.top-holders-container {
    width: 470px;
    margin: 10px;  
    background-color: #1f1f1f;
    color: white;
    border-radius: 12px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    margin-left: 0px;
}

  
  .holders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
  }
  
  .holders-table th, .holders-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #444;
  }
  
  .holders-table th {
    background-color: #333;
  }
  
  .holders-table td {
    background-color: #282828;
  }
  
  .holders-table td:nth-child(2) {
    font-family: monospace;
  }
  
  .holders-table td:nth-child(3) {
    text-align: right;
  }
  
  .holders-table tr:hover {
    background-color: #444;
  }
  