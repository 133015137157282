/* Modal container styles */
.token-modal {
    width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    outline: none;
    position: relative;
    /* font-family: Arial, sans-serif; */
  }
  
  .token-modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal Header */
  .token-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }


  
  .token-modal-header h2 {
    font-size: 1.5rem;
    color: black;
    margin-left: 0px;
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  /* Modal Body */
  .token-modal-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .token-modal-body label {
    font-weight: bold;
  }
  
  .token-modal-body input,
  .token-modal-body textarea {
    width: 95%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  .token-modal-body textarea {
    height: 80px;
    resize: none;
  }
  
  .create-token-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .create-token-button:hover {
    background-color: #45a049;
  }
  

  .seed-token-section {
    margin-top: 20px;
  }
  
  .seed-token-section label {
    font-weight: bold;
  }
  
  .seed-token-section p {
    color: #666;
    font-size: 0.9rem;
  }
  
  .summary-section {
    margin-top: 20px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }
  
  .create-token-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .create-token-button:hover {
    background-color: #45a049;
  }

  @font-face {
    font-family: 'VCR_OSD_MONO';
    src: url('../VCR_OSD_MONO_1.001.ttf') format('truetype');
  }
  
  body {
    font-family: 'VCR_OSD_MONO', sans-serif;
  }