.transaction-table {
    width: 62%;
    margin-top: -90px;
    margin-left: 10px;
    background-color: #1a1c22;
    color: #e6e6e6;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  thead {
    background-color: #333;
    color: #fff;
  }
  
  thead th {
    padding: 10px;
    font-weight: bold;
  }
  
  tbody tr {
    border-bottom: 1px solid #444;
  }
  
  tbody tr:last-child {
    border-bottom: none;
  }
  
  tbody td {
    padding: 15px;
    font-size: 14px;
  }
  
  .buy {
    color: #39d353;
  }
  
  .sell {
    color: #ff5c5c;
  }
  
  a {
    color: #61dafb;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  