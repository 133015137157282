.how-it-works-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-it-works-modal {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-content {
  text-align: center;
}

.highlight {
  font-weight: bold;
  color: #61dafb;
}

.open-modal-btn, .action-button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.open-modal-btn:hover, .action-button:hover {
  background-color: #21a1f1;
}
