/* Main container styling */
.main-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #372D91;
    font-family: 'Arial', sans-serif;
  }
  
  /* Header styling */
  .header {
    width: 100%;
    padding: 20px;
    background-color: #282c34;
    color: white;
    text-align: center;
    position: relative;
  }
  
  .connect-button {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  h1 {
    margin: 0;
    font-size: 36px;
  }
  
  .create-token-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3988ef;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #282c34;
    color: white;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .create-token-btn:hover {
    background-color: #21a1f1;
  }
  
  /* Token pools section */
  .token-pools-section {
    width: 90%;
    max-width: 1200px;
    margin-top: 40px;
  }
  
  h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: white;
    margin-left: 450px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  /* Token pools grid styling */
  .token-pools-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 column grid */
    gap: 20px;
  }

  .desc11 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2b2f36; /* Dark background */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
    margin: 20px auto;
    max-width: 800px;
    color: white; /* White text for contrast */
    font-family: 'VCR_OSD_MONO', sans-serif;
}

@font-face {
    font-family: 'VCR_OSD_MONO';
    src: url('./VCR_OSD_MONO_1.001.ttf') format('truetype');
  }
  
  body {
    font-family: 'VCR_OSD_MONO', sans-serif;
  }

  .header997{
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  