.token-pools-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates 2 columns */
    gap: 20px; /* Space between cards */
    padding: 20px;
  }
  
  .token-card {
    background-color: #282c34;
    padding: 20px;
    border-radius: 8px;
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
   .Loading88{
    color: white;
   }
  