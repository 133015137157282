.bonding-progress {
    width: 121%;
    height: 20px;
    background-color: #1a1a1a;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 0px;
    
  }
  
  .progress-bar {
    height: 100%;
    background-color: yellow;
    border-radius: 5px;
  }

.bonding-details{
  display: flex;
    flex-direction: column;
    /* background-color: #1a1a1a; */
    /* border-radius: 8px; */
    /* padding: 20px; */
    /* position: fixed; */
    right: 75px;
    /* top: 640px; */
    width: 420px; 
    /* position: fixed; */
    z-index: 1000; /* Ensure it appears above other content */
    margin-top: -40px;


    /* color: white; */
}

.p{
  margin-right: 40px;
}
  