
.box998 {
    background-color: #1e1f24;
    border-radius: 12px;
    padding: 0px;
    width: 450px; /* Increase width */

    max-width: 850px;
    float: right; 
    margin: 44px auto;
    color: #930c0c;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* position: fixed; */
 
    right: 0px; /* Adjust distance from the right edge */

    z-index: 1000; /* Ensure it appears above other content */
}

  
  .logoContainer123 {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: -60px;
  }
  
  .tokenLogo456 {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    background-color: #fff;
  }
  
  .tokenInfo789 {
    margin-bottom: 5px;
  }
  
  .tokenName112 {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: -10px;
    margin-left: 100px;

    

  }
  
  .tokenSymbol324 {
    font-size: 12px;
    color: #aaa;
  }
  
  .socialLinks210 {
    margin-bottom: 15px;
  }
  
  .socialLinks210 a {
    display: inline-block;
    margin: 0 10px;
    color: #61dafb;
    text-decoration: none;
  }
  
  .socialLinks210 a:hover {
    color: #1e90ff;
  }
  
  .tokenDescription897 {
    font-size: 14px;
    color: #ddd;
    margin-top: 15px;
  }

  .container444 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    gap: -25px;
  }
  
  .leftcolumn999 {
    flex: 2; /* Take more space on the left side */
  }
  
  .rightcolumn99 {
    flex: 1; /* Take less space on the right side */
    display: flex;
    flex-direction: column;
    margin-right: 36px;
    /* gap: 10px; */
  }
  
  .box998, .buy-sell-interface {
    background-color: #1a1a1a;
    border-radius: 12px;
    padding: 15px;
    width: 100%;
    color: white;
  }
  
  