.token-card {
  display: flex;
  align-items: center;
  background-color: #282c34;
  padding: 20px;
  border-radius: 15px;
  border: 2px solid #e3ef61;
  color: white;
  box-shadow: 0px 4px 10px rgba(219, 213, 101, 0.1);
  width: 500px;
  margin-bottom: 20px; /* Add spacing between token cards */
  font-family: 'VCR_OSD_MONO', sans-serif;

}

.token-image-container {
  flex: 0 0 100px;
  margin-right: 20px;
}

.token-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.token-details {
  flex: 1;
}

.token-details h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.token-details p {
  margin-top: 10px;
  font-size: 14px;
  color: #aaa;
}

a {
  color: yellow;
  text-decoration: none;
}

/* a:hover {
  text-decoration: underline;
} */


.bonding-details-wrapper {
  position: relative;
  margin-top: 45px; /* Adjust this to move it down */
  width: 100%;
  /* padding: 0px; */
  /* background-color: #282c34; */
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.bonding-details-wrapper .bonding-details-container {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: inherit; /* inherit the background */
}

@font-face {
  font-family: 'VCR_OSD_MONO';
  src: url('../VCR_OSD_MONO_1.001.ttf') format('truetype');
}

body {
  font-family: 'VCR_OSD_MONO', sans-serif;
}

