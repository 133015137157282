.chart-container {
    width: 95%;
    max-width: 1200px;
    margin: 0px 0px; /* 40px top/bottom, 20px left/right */
    padding: 10px;
    background-color: #1a1c22;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 540px;
    /* position: fixed; */
}
  
  .token-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .token-info {
    display: flex;
    align-items: center;
  }
  
  .token-logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
  }
  
  .token-name {
    font-size: 28px;
    color: white;
    font-weight: bold;
  }
  
  .token-stats {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .info-item {
    background-color: #282c34;
    padding: 10px 15px;
    border-radius: 8px;
    text-align: center;
    color: white;
  }
  
  .info-title {
    font-size: 10px;
    color: #8a8d93;
  }
  
  .info-value {
    font-size: 9px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .info-value.green {
    color: #3aff86;
  }
  
  .chart {
    width: 100%;
    height: 400px;
  }
  
  .chart-actions button {
    background-color: #3a3d45;
    border: none;
    color: #8a8d93;
    padding: 10px 15px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .chart-actions button.active {
    background-color: #4a4f57;
    color: white;
  }
  
  .chart-actions button:hover {
    background-color: #4a4f57;
  }
  
  .chart-details {
    margin-top: 20px;
    color: #8a8d93;
  }

  .explorer-link {
    color: rgb(195, 204, 27);
    text-decoration: none;
    cursor: pointer; /* Change cursor to pointer */
  }
  
  .explorer-link:hover {
    text-decoration: underline;
  }

  
  