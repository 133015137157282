.notification {
    position: fixed;
    right: 20px;
    top: 20px;
    padding: 15px 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    z-index: 1000;
    opacity: 0.9;
    animation: fadeInOut 3s ease;
    height: 20px;
  }
  
  .notification.error {
    background-color: #f44336;
  }
  
  @keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 0.9; }
    90% { opacity: 0.9; }
    100% { opacity: 0; }
  }
  