
.buy-sell-interface {
  width: 200px;
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 10px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-family: 'VCR_OSD_MONO', sans-serif;

}
  
  .switch-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .switch-buttons button {
    width: 45%;
    padding: 10px;
    background-color: #1a1a1a;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .switch-buttons .active {
    background-color: #00ff00;
    color: black;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .buy-section, .sell-section {
    display: flex;
    flex-direction: column;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  input[type="number"] {
    background-color: #333;
    color: white;
    border: 1px solid #555;
    padding: 12px;
    border-radius: 5px;
    margin-top: 10px;
    font-size: 16px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .percentage-buttons {
    margin-top: 15px;
    display: flex;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .percentage-buttons button {
    background-color: #333;
    color: white;
    border: none;
    padding: 8px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 4px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .place-trade-button {
    background-color: #00ff00;
    color: black;
    border: none;
    padding: 12px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .comment-section {
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .comment-section label {
    margin-left: 10px;
  }
  
  .comment-section input[type="checkbox"] {
    width: 18px;
    height: 18px;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }

  .input-container {
    position: relative;
    width: 100%;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  input[type="number"] {
    width: 100%;
    padding-right: 40px; /* Enough space for the token symbol */
    box-sizing: border-box;
    font-family: 'VCR_OSD_MONO', sans-serif;

  }
  
  .token-symbol {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff; /* Customize this color to fit your theme */
    font-family: 'VCR_OSD_MONO', sans-serif;

  }

  @font-face {
    font-family: 'VCR_OSD_MONO';
    src: url('../../VCR_OSD_MONO_1.001.ttf') format('truetype');
  }
  
  body {
    font-family: 'VCR_OSD_MONO', sans-serif;
  }
  