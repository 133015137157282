.dapp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#1C113A;
    padding: 15px 30px;
    color: white;
    width: 96%;
    box-shadow: black;
    border: 1px solid yellow;
    font-family: 'VCR_OSD_MONO', sans-serif;


  }
  
  .header-left h1 {
    font-size: 24px;
    height: 30px;
  }
  
  .header-center a {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .header-center a:hover {
    color: #ffcc00;
  }
  
  .connect-button {
    background-color: #ffcc00;
    border: none;
    color: black;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .connect-button:hover {
    background-color: #ffd700;
  }
  
  .connected-account {
    color: #ffcc00;
    font-weight: bold;
  }

  .imagedesign{
    width: 180px;
    height: 45px;
  }

  @font-face {
    font-family: 'VCR_OSD_MONO';
    src: url('../../VCR_OSD_MONO_1.001.ttf') format('truetype');
  }
  
  body {
    font-family: 'VCR_OSD_MONO', sans-serif;
  }
  
  .header-right {
    display: flex;
    gap: 10px;
    align-items: center;
  }