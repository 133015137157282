/* ChainSwitcher.css */

.chain-switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px; /* Fixed width */
    padding: 10px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative; /* For dropdown positioning */
    transition: background-color 0.3s ease;
  }
  
  .chain-switcher:hover {
    background-color: #f0f0f0;
  }
  
  .chain-logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .chain-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    flex-grow: 1;
    text-align: left;
  }
  
  .dropdown-arrow {
    font-size: 14px;
    color: #333;
  }
  
  /* Dropdown list styling */
  .chain-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 10;
  }
  
  .chain-item {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .chain-item:hover {
    background-color: #f0f0f0;
  }
  
  .chain-item.selected {
    background-color: #86e2b2;
  }
  
  .chain-item img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .chain-item span {
    font-size: 14px;
    color: #333;
  }
  